














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RatingCard extends Vue {
  @Prop({ required: true})
  title!: string;

  @Prop({ required: false })
  icon!: string;
}
