








import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { GlobalTag } from '@/store/global_tag';

@Component
export default class TagIcon extends Vue {

  /**
   * 大きさ（px）
   */
  @Prop({ default: 16 })
  size!: number;

  /**
   * 表示するタグのキー
   */
  @Prop({ required: true })
  icon!: string;

  @Inject()
  globalTagModule!: GlobalTag;

  resolver(key: string) {
    return this.globalTagModule.icon(key);
  }

}
