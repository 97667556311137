

























































































































import GlobalEvents from 'vue-global-events';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import Favorite from '@/api/models/favorite';
import Me from '@/api/models/me';
import Tag from '@/api/models/tag';
import BigButton from '@/components/BigButton.vue';
import CaseStudyFavoritesForm from '@/components/CaseStudyFavoritesForm.vue';
import CaseStudyShowFiles from '@/components/CaseStudyShowFiles.vue';
import CaseStudyShowSummary from '@/components/CaseStudyShowSummary.vue';
import RatingCard from '@/components/RatingCard.vue';
import CoreTitle from '@/components/utils/CoreTitle.vue';
import promiseNoop from '@/utils/promise_noop';

@Component({
  components: {
    CaseStudyFavoritesForm,
    CaseStudyShowSummary,
    CaseStudyShowFiles,
    GlobalEvents,
    CoreTitle,
    BigButton,
    RatingCard,
  }
})
export default class CaseStudyShowTemplate extends Vue {

  /**
   * 事例データ
   */
  @Prop({ required: true })
  caseStudy!: CaseStudy;

  /**
   * タグ情報
   */
  @Prop({ required: true })
  tags!: Tag[];

  /**
   * AttachmentPDFデータ読み込み
   */
  @Prop({ required: true })
  attachmentPdfLoad!: (attachmentUuid: string) => Promise<ArrayBuffer | undefined>;

  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;

  /**
   * getFavorites APIコールバック
   */
  @Prop({ required: true })
  getFavorites!: () => Promise<{ items: Favorite[]; totalCount: number }>;

  /**
   * putFavoriteCaseStudy APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  putFavoriteCaseStudy!: (favorite: Favorite, caseStudy: CaseStudy) => Promise<void>;

  /**
   * deleteFavoriteCaseStudy APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  deleteFavoriteCaseStudy!: (favorite: Favorite, caseStudy: CaseStudy) => Promise<void>;

  @Prop()
  previous?: () => Promise<void>;

  @Prop()
  next?: () => Promise<void>;

  favorites: Favorite[] = [];

  disabledEsc = false;

  changedFullscreen(fullscreen: boolean) {
    this.disabledEsc = fullscreen;
  }

  async created() {
    this.favorites = (await this.getFavorites()).items;
  }

  onOutsideClick(event: Event) {
    const dialog = this.$refs.dialog as Element;

    // ダイヤログ、またはダイヤログ内、のクリックだったかどうかを見極める。
    const hit =
      dialog === event.target ||
      dialog.contains(event.target as Node);

    if (!hit) {
      this.$emit('close');
    }
  }

  onTypeLeftKey() {
    if (this.previous) {
      this.previous();
    }
  }

  onTypeRightKey() {
    if (this.next) {
      this.next();
    }
  }
  
  onPrevious() {
    if (this.previous) {
      this.previous();
    }
  }

  onNext() {
    if (this.next) {
      this.next();
    }
  }

  get showEditButton() {
    return this.me.canEditCaseStudy(this.caseStudy); // この事例に対する編集権限を持つときのみ表示
  }

  async onAddToFavorite(favorite: Favorite) {
    await this.putFavoriteCaseStudy(favorite, this.caseStudy);
  }

  async onRemoveFromFavorite(favorite: Favorite) {
    await this.deleteFavoriteCaseStudy(favorite, this.caseStudy);
  }

}
