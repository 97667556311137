





import { Dayjs } from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DateText extends Vue {
  @Prop({ required: true })
  date!: Dayjs;

  @Prop({ required: true })
  format!: string;
}
