




















import { Component, Prop, Vue } from 'vue-property-decorator';

import TagDt from '@/api/models/tag_dt';
import { TagCardVariant } from '@/components/utils/TagCard';
import TagIcon from '@/components/utils/TagIcon.vue';

@Component({
  components: {
    TagIcon,
  }
})
export default class TagCard extends Vue {
  /**
   * タグ詳細
   */
  @Prop({ required: true })
  tagDt!: Required<TagDt>;

  /**
   * ボタンの表示形式
   */
  @Prop({ required: true })
  variant!: TagCardVariant;
}
