



















import { difference, find } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import Favorite from '@/api/models/favorite';
import promiseNoop from '@/utils/promise_noop';

@Component
export default class CaseStudyFavoritesForm extends Vue {

  /**
   * お気に入りリスト
   */
  @Prop({ required: true })
  favorites!: Favorite[];

  /**
   * 事例(v-modelとして使用)
   */
  @Prop({ required: true })
  value!: CaseStudy;

  /**
   * お気に入りに追加
   */
  @Prop({ default: () => promiseNoop })
  add!: (favorite: Favorite) => Promise<void>;

  /**
   * お気に入りから削除
   */
  @Prop({ default: () => promiseNoop })
  remove!: (favorite: Favorite) => Promise<void>;

  active = false;

  get items() {
    return this.favorites.map(favorite => ({ label: favorite.title, value: favorite.uuid }));
  }

  get select() {
    return this.value.favoriteUuidArray;
  }

  set select(value: string[]) {
    this.setSelect(value, this.value.favoriteUuidArray);
  }

  async setSelect(newValue: string[], oldValue: string[]) {
    if (this.active) return;
    try {
      this.active = true;

      // 追加
      if (newValue.length > oldValue.length) {
        const addFavorite = difference(newValue, oldValue);
        await this.onAdd(addFavorite[0]);

      // 削除
      } else if (newValue.length < oldValue.length) {
        const removeFavorite = difference(oldValue, newValue);
        await this.onRemove(removeFavorite[0]);

      }

      this.value.favoriteUuidArray = newValue;
    } finally {
      this.active = false;
    }
  }

  async onAdd(favoriteUuid: string) {
    const favorite = find(this.favorites, ['uuid', favoriteUuid]);
    if (favorite)
      await this.add(favorite);
  }

  async onRemove(favoriteUuid: string) {
    const favorite = find(this.favorites, ['uuid', favoriteUuid]);
    if (favorite)
      await this.remove(favorite);
  }

}
