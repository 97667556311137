





















import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import FieldRow from '@/components/utils/FieldRow.vue';
import PdfPreview from '@/components/utils/PdfPreview.vue';

@Component({
  components: {
    PdfPreview,
    FieldRow,
  }
})
export default class CaseStudyShowFiles extends Vue {

  /**
   * 事例データ
   */
  @Prop({ required: true })
  caseStudy!: CaseStudy;

  /**
   * AttachmentPDFデータ読み込み
   */
  @Prop({ required: true })
  attachmentPdfLoad!: (attachmentUuid: string) => Promise<ArrayBuffer>;

  @Prop({ default: () => noop })
  changedFullscreen!: (pdfFullscreen: boolean) => void;

  @Prop()
  previous?: () => Promise<void>;

  @Prop()
  next?: () => Promise<void>;

}
