























































import { chain } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import Me from '@/api/models/me';
import Tag from '@/api/models/tag';
import DateText from '@/components/DateText.vue';
import FieldGroup from '@/components/utils/FieldGroup.vue';
import FieldRow from '@/components/utils/FieldRow.vue';
import TagCard from '@/components/utils/TagCard.vue';

@Component({
  components: {
    FieldRow,
    FieldGroup,
    DateText,
    TagCard,
  }
})
export default class CaseStudyShowSummary extends Vue {

  /**
   * 事例データ
   */
  @Prop({ required: true })
  caseStudy!: CaseStudy;

  /**
   * タグ情報
   */
  @Prop({ required: true })
  tags!: Tag[];

  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;

  get showPublishKbn() {
    return this.me.canEditCaseStudy(this.caseStudy); // この事例に対する編集権限を持つときのみ表示
  }

  get dtsByTagUuid() {
    return chain(this.tags)
      .keyBy('uuid')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .mapValues(tag => tag.findDts(this.caseStudy.tagDtUuidArray!))
      .value();
  }
}
