

































import { noop } from 'lodash';
import GlobalEvents from 'vue-global-events';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import PdfViewer from '@/components/utils/PdfViewer.vue';

@Component({
  components: {
    PdfViewer,
    GlobalEvents
  }
})
export default class PdfPreview extends Vue {
  fullscreen = false;
  
  @Prop({ default: () => noop })
  changedFullscreen!: (fullscreen: boolean) => void;

  @Watch('fullscreen', { immediate: true})
  fullScreen() {
    this.changedFullscreen(this.fullscreen);
  }

  onClick() {
    this.fullscreen = true;
  }

}
