













import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class NavTabButton extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  onClick() {
    if (!this.disabled) {
      this.$emit('click');
    }
  }
}
