













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FieldRow extends Vue {

  /**
   * タイトル（項目名）
   */
  @Prop({ required: true })
  title!: string;

  /**
   * 必須表記
   */
  @Prop({ default: false })
  required!: boolean;

  /**
   * タイトル幅（px）
   */
  @Prop({ default: 134 }) // デフォルト幅としてfigmaでデザインされた幅を指定
  titleWidth!: number;
}
