









































































































import { Pagination } from '@uniquevision/libraries.beluga_ui/src/components/UvPageControls';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import GlobalEvents from 'vue-global-events';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

import BigButton from '@/components/BigButton.vue';
import CoreSpinner from '@/components/utils/CoreSpinner.vue';
import IconButton from '@/components/utils/IconButton.vue';
import PageControlsStretch from '@/components/utils/PageControlsStretch.vue';

import { PdfLoad, PdfResource } from './PdfViewer';

export const PdfLoadDefault: PdfLoad = async (resource) => {
  if (!resource || typeof resource === 'string') throw new Error('not pdf data');
  else return resource;
};

type State = 'loading' | 'loaded' | 'failed';

@Component({
  components: {
    CoreSpinner,
    BigButton,
    PageControlsStretch,
    IconButton,
    GlobalEvents,
  }
})
export default class PdfViewer extends Vue {
  /**
   * PDFのリソース
   * pdfjsで直接開ける`Uint8Array`か`BufferSource`型で指定する。
   * `Blob`型から値を渡したいときは`blob.arrayBuffer()`で`ArrayBuffer`(`BufferSource`型)に変換すると良い。
   * `string`型を渡す場合は`load`PropでPDFデータの読み込み処理を記述する必要がある
   */
  @Prop({ required: true })
  resource!: PdfResource;

  /**
   * PDFのロード処理
   * この処理中は読み込み中を表示する
   */
  @Prop({ default: () => PdfLoadDefault })
  load!: PdfLoad;

  /**
   * PDFの本体をクリックした場合
   */
  @Prop()
  click?: () => void;

  @Prop({ default: false})
  largeMode!: boolean;

  @Prop()
  previous?: () => Promise<void>;

  @Prop()
  next?: () => Promise<void>;

  /**
   * 文字を白にする場合指定する。背景が暗いときに利用する
   */
  @Prop({ type: Boolean })
  textWhite?: boolean;

  @Prop({default: false})
  controllable!: boolean;

  @Ref('canvas')
  canvas!: HTMLCanvasElement;

  pageNum = 0; // ページ番号
  numPages = 0; // 総ページ数
  pdfDocument?: pdfjsLib.PDFDocumentProxy;
  active = false;
  status: State = 'loading';
  pagination: Pagination | null = null;

  get disabledNext() {
    return this.active
      || this.pageNum >= this.numPages
    ;

    return false;
  }

  get disabledPrev() {
    return this.active
      || this.pageNum <= 1
    ;

    return false;
  }

  async mounted() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    this.reloadPdf();
    this.canvas.oncontextmenu = function() {
      return false;
    };
  }

  @Watch('resource')
  async reloadPdf() {
    this.initialize();
    await this.loadPdfDocument();
    await this.viewPage(this.pageNum);
  }

  initialize() {
    this.pageNum = 0;
    this.numPages = 0;
    this.pdfDocument = undefined;
  }

  async loadPdfDocument() {
    try {
      this.status = 'loading';
      const pdfData = await this.load(this.resource);
      this.pdfDocument = await pdfjsLib.getDocument({
        data: pdfData,
        cMapUrl: '/pdf.js/cmaps/', // CopyWebpackPluginで公開した'node_modules/pdfjs-dist/cmaps/'
        cMapPacked: true,
      }).promise;
      this.numPages = this.pdfDocument.numPages;
      this.pageNum = 1;
      this.status = 'loaded';
    } catch (error) {
      this.status = 'failed';
      throw error;
    }
  }

  async viewPage(pageNum: number) {
    if (!this.pdfDocument) return;
    this.pageNum = pageNum;
    const page = await this.pdfDocument.getPage(pageNum);

    if (!this.canvas) return; // 読み込み完了前に閉じられるとcanvasがなくなる。
    const ctx = this.canvas.getContext('2d');
    if (!ctx) return;

    const viewport = page.getViewport({ scale: 2.0 });
    this.canvas.height = viewport.height;
    this.canvas.width = viewport.width;

    await page.render({
      canvasContext: ctx,
      viewport: viewport
    }).promise;
  }

  onClick() {
    if (typeof this.click === 'function') this.click();
  }

  async onNext() {
    this.active = true;
    if (this.pageNum < this.numPages) {
      this.pageNum += 1;
      await this.viewPage(this.pageNum);
    }
    this.active = false;
  }

  async onPrev() {
    this.active = true;
    if (this.pageNum > 1) {
      this.pageNum -= 1;
      await this.viewPage(this.pageNum);
    }
    this.active = false;
  }

  async onTypeCtrlShiftLeftKey() {
    await this.onPrev();
  }

  async onTypeCtrlShiftRightKey() {
    await this.onNext();
  }

  onPreviousCaseStudy() {
    if (this.previous) {
      this.previous();
    }
  }

  onNextCaseStudy() {
    if (this.next) {
      this.next();
    }
  }
}
