










import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
})
export default class IconButton extends Vue {
  @Prop({ required: true })
  icon!: string;

  @Prop({ default: true })
  disabled!: boolean;

  @Prop({ default: 0})
  rotate!: number;

  @Prop({ default: 30})
  size!: number;

  @Prop({ default: 'default'})
  variant!: string;
}
