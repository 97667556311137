











































import { Pagination } from '@uniquevision/libraries.beluga_ui/src/components/UvPageControls';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import IconButton from '@/components/utils/IconButton.vue';

function range(start: number, end: number, step = 1) {
  const values: number[] = [];

  for (let value = start; value !== end; value += step) {
    values.push(value);
  }

  return values;
}

@Component({
  components: {
    IconButton
  },
})
export default class PageControlsStretch extends Vue {

  /**
   * 現在のページ
   */
  @Prop({ required: true })
  page!: number;
  
  /**
   * 合計で何件がある
   */
  @Prop({ required: true })
  total!: number;
  
  /**
   * ページに何件がある
   */
  @Prop({ default: 10 })
  step!: number;
  
  /**
   * ページ数の表示数
   */
  @Prop({ default: 7 })
  length!: number;

  /**
   * ページの添え字のベース
   *
   * `page`に渡す添え字が1から始まる場合使う。
   */
  @Prop({ default: 0 })
  base!: 0 | 1;

  get pagination(): Pagination {
    return {
      total: this.total,
      start: Math.min(this.total, 1 + this.currentPage * this.step),
      end: Math.min(this.total, (this.currentPage + 1) * this.step),
    };
  }

  @Watch('pagination', { immediate: true, deep: true })
  syncPaginationOnChange() {

    /**
     * pagination.sync用
     * 
     * ページ情報が表示したい場合、:pagination.syncで得られる。
     */
    this.$emit('update:pagination', this.pagination);
  }
  
  get totalPages() {
    return Math.ceil(this.total / this.step);
  }
  
  get pages() {

    // ベースケース：合計は０である。
    if (this.total === 0) {
      return [
        0,
      ];
    }

    const [left, right] = this.sides;

    // ケース１：全部表示できる。
    if (this.totalPages <= this.length) {
      return range(0, this.totalPages);
    }
    
    // ケース２：最初のページを今のページと同じ部分で表示する。
    if (this.currentPage <= this.length - right - 3) {
      return [
        ...range(0, this.length - 2),
        null,
        this.totalPages - 1,
      ];
    }

    // ケース３：最後のページを今のページと同じ部分で表示する。
    if (this.currentPage > this.totalPages - (this.length - left - 1)) {
      return [
        0,
        null,
        ...range(
          this.totalPages - (this.length - 2),
          this.totalPages
        ),
      ];
    }

    // ケース４：今のページが端っこから遠すぎて、両側は「...」が必要。
    return [
      0,
      null,
      ...range(
        this.currentPage - left, 
        this.currentPage + right + 1
      ),
      null,
      this.totalPages - 1,
    ];
  }

  /**
   * 表示の左右に出るページ数を計算する：
   * 
   * 1 ...4 5 ⑥ 7 8 9...20
   *     |← →| |←   →|
   * 
   * 戻り値は [左、右] となっている。
   */
  get sides() {
    const side = Math.floor(this.length / 2) - 2;

    if (this.length % 2 === 0) {
      return [side - 1, side];

    } else {
      return [side, side];
    }
  }

  get currentPage() {
    return (this.page || this.base) - this.base;
  }

  set currentPage(page: number) {
    /**
     * ユーザーがページを変える時
     */
    this.$emit('seek', page + this.base);
  }
}
