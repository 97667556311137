
















import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import { ExternalFileApi } from '@/api/external_file_api';
import CaseStudy from '@/api/models/case_study';
import Me from '@/api/models/me';
import CaseStudyShowTemplate from '@/components/CaseStudyShowTemplate.vue';
import { CaseStudiesFavoriteState } from '@/store/case_studies_favorite_state';
import { CaseStudiesSearchState } from '@/store/case_studies_search_state';
import { CaseStudiesTabState } from '@/store/case_studies_tab_state';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMe } from '@/store/global_me';
import { GlobalTag } from '@/store/global_tag';
import { CaseStudyPositionKbn, KbnConst } from '@/utils/kbn';

@Component({
  components: {
    CaseStudyShowTemplate,
  }
})
export default class CaseStudyShow extends Vue {
  
  @Prop({ required: true })
  caseStudyUuid!: string;

  @Prop({ default: false })
  fromListFlag!: boolean;

  caseStudy = new CaseStudy();

  nextExistsFlag = false;
  previousExistsFlag = false;

  @Inject()
  api!: Api;

  @Inject()
  externalFileApi!: ExternalFileApi;

  @Inject()
  globalTagModule!: GlobalTag;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;
  
  @Inject()
  globalMeModule!: GlobalMe;

  @Inject()
  caseStudiesSearchState!: CaseStudiesSearchState;

  @Inject()
  caseStudiesFavoriteState!: CaseStudiesFavoriteState;

  @Inject()
  caseStudiesTabState!: CaseStudiesTabState;

  get searchForm() {
    return this.caseStudiesSearchState.form;
  }

  me = new Me();

  async created() {
    await this.globalLoadingScreenModule.track(
      this.getCaseStudy(KbnConst.CASE_STUDY_POSITION_KBN_MYSELF),   // 将来的には、getMeと一緒にpageの方へ移動する。
      this.getMe(),
    );
  }

  async getCaseStudy(caseStudyPositionKbn: CaseStudyPositionKbn) {
    if (this.fromListFlag) {
      await this.getCaseStudyWithNeighboringInfo(caseStudyPositionKbn);
    } else {
      await this.getCaseStudyWithoutNeighboringInfo();
    }
  }

  async getCaseStudyWithoutNeighboringInfo() {
    this.caseStudy = await this.api.getCaseStudy(this.caseStudyUuid);
    this.nextExistsFlag = false;
    this.previousExistsFlag = false;
  }

  async getCaseStudyWithNeighboringInfo(caseStudyPositionKbn: CaseStudyPositionKbn) {
    let res = null;
    if (this.tab === 'search') {
      res = await this.api.getNeighboringCaseStudy(this.caseStudyUuid, this.caseStudiesSearchState.form, caseStudyPositionKbn);
    } else {
      res = await this.api.getNeighboringFavoriteCaseStudy(this.caseStudyUuid, this.caseStudiesFavoriteState.form, caseStudyPositionKbn);
    }
    this.caseStudy = res.item;
    this.nextExistsFlag = res.nextExistsFlag;
    this.previousExistsFlag = res.previousExistsFlag;
  }

  async onPrevious() {
    await this.getCaseStudy(KbnConst.CASE_STUDY_POSITION_KBN_PREVIOUS);
    if (this.caseStudy.uuid) {
      try {
        this.$router.push({ name: 'CaseStudyShow', params: { 'case_study_uuid': this.caseStudy.uuid, 'from_list_flag': 'true' } });
      } catch (e) {
        // NavigationDuplicatedは無視してしまってよい。
      }
    }
  }

  async onNext() {
    await this.getCaseStudy(KbnConst.CASE_STUDY_POSITION_KBN_NEXT);
    if (this.caseStudy.uuid) {
      try {
        this.$router.push({ name: 'CaseStudyShow', params: { 'case_study_uuid': this.caseStudy.uuid, 'from_list_flag': 'true' } });
      } catch (e) {
        // NavigationDuplicatedは無視してしまってよい。
      }
    }
  }

  async getMe() {
    this.me = await this.globalMeModule.getMe();
  }

  async attachmentPdfLoad(uuid: string) {
    if (!this.caseStudy.uuid || !this.caseStudy.mainAttachment) throw new Error('not found attachment');
    const attach =  await this.api.getAttachment(this.caseStudy.uuid, uuid);
    if (!attach.s3PresignedUri) throw new Error('not found presigned uri');
    const blob = await this.externalFileApi.getFile(attach.s3PresignedUri);
    return await blob.arrayBuffer();
  }

  get tags() {
    return this.globalTagModule.tags;
  }

  get tab() {
    return this.caseStudiesTabState.tab;
  }

  onEdit() {
    if (this.caseStudy.uuid) {
      this.$router.push({ name: 'CaseStudyEdit', params: { 'case_study_uuid': this.caseStudy.uuid } });
    }
  }

  onClose() {
    this.$router.push({ name: 'CaseStudiesIndex' });
  }

}
